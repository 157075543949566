<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-newspaper"/>
                  E-Mail-Newsletter
                </div>
                <div class="header-button">
                  <CButton
                      to="article/new"
                      name="backagain"
                      size="sm"
                      key="key"
                      color="dark">
                    <CIcon size="sm" name="cil-plus"/>
                    <span> Neuen E-Mail-Newsletter erstellen</span>
                  </CButton>
                </div>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody v-if="!loading">
            <CDataTable
                :items="tableData"
                :itemsPerPageSelect="{ label: 'E-Mails pro Seite' }"
                :fields="getTableFields()"
                :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar',
                    noItems: 'Keine Newsletter verfügbar' }"
                hover
                striped
                bordered
                fixed
                column-filter
                table-filter
                :tableFilter="{ label: 'E-Mail suchen', placeholder: 'Suchbegriff eingeben' }"
                items-per-page-select
                sorter
                :sorterValue="{ column: 'sendAt', asc: true }"
                pagination
                :loading="tableShowLoading"
            >
              <template #active="{item}">
                <td>
                  <CBadge v-if="item.archived" color="warning">
                    Versendet
                  </CBadge>
                  <CBadge v-else :color="getTableBadge(item.active)">
                    {{item.active ? 'Aktiv' : 'Deaktiv'}}
                  </CBadge>
                </td>
              </template>
              <template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                      :to="'article/edit/' + item.id"
                      color="primary"
                      variant="outline"
                      square
                      size="sm"
                  >
                    Bearbeiten
                  </CButton>
                </td>
              </template>
              <template #sendAt="{ item }">
                <td>
                  {{ $moment(item.sendAt).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
            </CDataTable>
          </CCardBody>
        </CCard>
        <CCard>
          <CCardHeader>
            <slot name="header">
              <strong>Hilfe</strong>
            </slot>
          </CCardHeader>
          <CCardBody>
            <p>
              Hier können Sie E-Mail-Newsletter erstellen und den Versand planen. <!--<br />
              Die E-Mail-Newsletter werden nach dem Versand automatisch archiviert und können später im Archiv abgerufen werden.-->
            </p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import commons from '@/commons'
import axios from 'axios'
export default {
  name: 'NewsletterArticles',
  components: {
    DoubleBounceLoader
  },
  data () {
    return {
      loading: false,
      saving: false,
      tableData: [],
      tableShowLoading: true
    }
  },
  mounted () {
    this.loading = true
    this.loadNewsletterSubscriber()
  },
  methods: {
    loadNewsletterSubscriber() {
      axios.get('/newsletter/article')
          .then(response => {
            let content = []
            response.data.forEach(value => {
              content.push(commons.flatObject(value))
            })
            this.tableData = content
            this.loading = false
          })
          .catch(err => console.error('Problem while fetching newsletter articles', err))
          .finally(() => this.tableShowLoading = false)
    },
    getTableFields() {
      return [
        {key: 'name', label: 'Name'},
        {key: 'subject', label: 'Betreff'},
        {key: 'active', label: 'Aktiv?'},
        {key: 'sendAt', label: 'Senden am'},
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    getTableBadge(type) {
      return type ? 'success' : 'danger'
    }
  }
}
</script>
